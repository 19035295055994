import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./service-and-team-organization@gen.scss";

const GeneratedProductFeaturesServiceAndTeamOrganization = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          サービスとチームの組織 - PagerDuty正規代理店 - 株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="個々のサービスからのアラートを集約して、組織のアプリケーションおよびサービスインフラストラクチャを包括的に把握
"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="個々のサービスからのアラートを集約して、組織のアプリケーションおよびサービスインフラストラクチャを包括的に把握
"
        />

        <meta
          property="og:title"
          content="サービスとチームの組織 - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="個々のサービスからのアラートを集約して、組織のアプリケーションおよびサービスインフラストラクチャを包括的に把握
"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/service-and-team-organization/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/service-and-team-organization/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="サービスとチームの組織"
        overview=""
      />

      <div className="satoWrapper">
        <div className="center-2 first-section">
          <h2 className="center-title">
            個々のサービスからのアラートを集約して、
            <br />
            組織のアプリケーションおよび
            <br />
            サービスインフラストラクチャを包括的に把握
          </h2>
          <p className="center-para">
            PagerDutyで複数の統合を簡単にグループ化し、サービスのさまざまなレイヤーを監視します。
          </p>
        </div>
        <div className="max-930px-img">
          <img
            className="object-cover"
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/saaf_infographic_1_46287a2ab6.jpg"
            alt="saaf infographic"
          />
        </div>
        {/* details */}
        <div className="center-1">
          <h2 className="center-title">サービスとチームの組織について</h2>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>一貫したビュー</p>
            </div>
            <p>
              サービスがITインフラストラクチャにどのように配備されているかに合わせて、サービスとチームを構成することができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>グループ統合</p>
            </div>
            <p>
              個々の管理サイロではなく、モニタリング、チケット、オートメーション、および導入ツールを統合し、それらを集約します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>既存のインテグレーションを移動する</p>
            </div>
            <p>
              既存のインテグレーションで、1つのサービスから別のサービスにシームレスに移行できます。また、1つ以上のサービスの下で簡単に統合できます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>迅速なインシデント解決</p>
            </div>
            <p>
              テクノロジーサイロではなく、サービスレベルでの問題を解決します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>信頼できる通知とエスカレーション</p>
            </div>
            <p>
              PagerDutyのサービスは、PagerDutyで設定した通知、スケジューリングおよびエスカレーションポリシーを使用し、通知を保証します。
            </p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesServiceAndTeamOrganization;
